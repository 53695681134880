import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/home.vue'
Vue.use(Router)

const router = new Router({
    // mode: "history",// 使用history 模式需要ngingx 配置
    routes: [{
        path: '/',
        redirect: '/index'
    }, {
        path: '/',
        component: Home,
        children: [{
            path: '/index',
            desc: '首页',
            component: () => import('@/views/homePage.vue')
        }, {
            path: '/contact',
            desc: '首页',
            component: () => import('@/views/contact.vue')
        }, {
            path: '/productcenter',
            name: "productcenter",
            component: () => import('@/views/productcenter.vue')
        }, {
            path: '/companyprofile',
            name: "companyprofile",
            component: () => import('@/views/companyprofile.vue')
        }, {
            path: '/newscenter',
            name: "newscenter",
            component: () => import('@/views/newscenter.vue')
        }, {
            path: '/information',
            name: "information",
            component: () => import('@/views/information.vue')
        }, {
            path: '/newsdetails',
            name: 'newsdetails',
            component: () => import('@/views/newsdetails.vue')
        }, {
            path: '/recruitdetails',
            name: 'recruitdetails',
            component: () => import('@/views/recruitdetails.vue')
        }, {
            path: '/goodDetail',
            name: 'goodDetail',
            component: () => import('@/views/goodDetail.vue')
        }, {
            path: '/downloadApp',
            name: 'downloadApp',
            component: () => import('@/views/downloadApp.vue')
        }
        ]
    }],
    // 解决路由跳转后，不显示在首行
    scrollBehavior () {
        return {
            x: 0,
            y: 0
        };
    }
})

//路由跳转后，页面回到顶部
router.afterEach(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
})

export default router